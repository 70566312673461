// document.addEventListener("DOMContentLoaded", () => {
//   const resizeChat = () => {
//     let isMobile = window.innerWidth < 993;
//     let isFirefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
//     let isFirefoxMobile = navigator.platform.toLowerCase().indexOf("mobile") > -1 || navigator.platform.toLowerCase().indexOf("tablet") > -1;
//     let chatBox = Array.from(document.querySelectorAll("solid-xmpp-chat"))
//       .map((el) => el.shadowRoot ? el.shadowRoot.getElementById("conversejs") : false)
//       .filter((el) => el)
//       .pop();
//     if (chatBox) {
//       let chatTextArea = chatBox.querySelector(".message-form-container");
//       let ischatTextArea = setInterval(() => {
//         chatTextArea = chatBox.querySelector(".message-form-container");
//         if (chatTextArea) {
//           clearInterval(ischatTextArea);
//           if (
//             chatBox.getBoundingClientRect().height -
//             chatTextArea.getBoundingClientRect().height !=
//             chatTextArea.offsetTop
//           ) {
//             let viewportChat = Array.from(
//               document.querySelectorAll("#viewport .chat-view")
//             );
//             viewportChat.forEach(
//               (c) => (c.style.height = isMobile ? (isFirefox && isFirefoxMobile) ? "calc(100vh - 106px - 57px)" : "calc(100vh - 106px)" : "calc(100vh - 64px - 83px)")
//             );
//           }
//         }
//       }, 15);
//     }
//   };
//   resizeChat();

//   let isbody = setInterval(() => {
//     if (document.body) {
//       clearInterval(isbody);
//       resizeChat();
//     }
//   }, 50);

//   window.addEventListener("load", () => {
//     setTimeout(() => {
//       resizeChat();
//     }, 0);
//   });

//   let windowResizing;
//   window.addEventListener("resize", () => {
//     windowResizing = setTimeout(() => {
//       clearInterval(windowResizing);
//       resizeChat();
//     }, 50);
//   });

//   document.addEventListener("navigate", () => {
//     setTimeout(() => {
//       resizeChat();
//     }, 0);
//   });
// });