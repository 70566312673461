document.addEventListener("DOMContentLoaded", function () {
  document
    .querySelector("sib-auth")
    .getUser()
    .then(user => {
      if (user !== null) {
        document
          .querySelectorAll(".notLoggedIn")
          .forEach(el => (el.style.visibility = "visible"));
          document
            .querySelectorAll(".loggedIn")
            .forEach(el => (el.style.display = "none"));
      }
    });
});