// auxiliary function closes the user profile menu
function closeUserControls() {
    let userControls = Array.from(document.querySelectorAll(".user-controls"));
    userControls.forEach(e => e.removeAttribute("open"));
  }
  
  function closeLeftMenu() {
    let leftMenu = document.querySelector("#main__menu");
    if (leftMenu) leftMenu.removeAttribute("open");
  }
  
  function closeRightMenu() {
    let rightMenu = document.querySelectorAll(".jsRightMenu");
    if (Array.from(rightMenu).filter(el => el.hasAttribute("open")).length > 0) {
      Array.from(document.querySelectorAll(".views-container")).forEach(vC =>
        vC.classList.toggle("sidebar-is-closed")
      );
      Array.from(document.querySelectorAll(".jsOffsiteToggle")).forEach(el => {
        el.querySelector('.text-right').setAttribute('hidden', '');
        el.querySelector('.text-left').removeAttribute('hidden');
      });
      Array.from(rightMenu).forEach(el => el.removeAttribute("open"));
    }
  }
  
  function openRightMenu() {
    let rightMenu = document.querySelectorAll(".jsRightMenu");
    Array.from(rightMenu).forEach(el => el.setAttribute("open", ""));
    Array.from(document.querySelectorAll(".jsOffsiteToggle")).forEach(el => {
      el.querySelector('.text-left').setAttribute('hidden', '');
      el.querySelector('.text-right').removeAttribute('hidden');
    });
    Array.from(document.querySelectorAll(".views-container")).forEach(vC =>
      vC.classList.toggle("sidebar-is-closed")
    );
  }

document.addEventListener("DOMContentLoaded", function() {
  
    //- View change event
    window.addEventListener("navigate", e => {
      if(e.detail.route.startsWith('admin-')) {
        document.querySelector('.only-on-admin').removeAttribute('hidden');
      } else {
        document.querySelector('.only-on-admin').setAttribute('hidden','');
      }
      if(e.detail.route.startsWith("admin-communities")) {
        document.querySelector('solid-route[name="admin-communities"]').setAttribute('active','');
        document.querySelector('.jsRightMenu solid-link[next="admin-communities"]').setAttribute('active','');
      } else {
        document.querySelector('solid-route[name="admin-communities"]').removeAttribute('active');
        document.querySelector('.jsRightMenu solid-link[next="admin-communities"]').removeAttribute('active');
      }
      if(e.detail.route.startsWith("admin-circles")) {
        document.querySelector('solid-route[name="admin-circles"]').setAttribute('active','');
        document.querySelector('.jsRightMenu solid-link[next="admin-circles"]').setAttribute('active','');
      } else {
        document.querySelector('solid-route[name="admin-circles"]').removeAttribute('active');
        document.querySelector('.jsRightMenu solid-link[next="admin-circles"]').removeAttribute('active');
      }
      if(e.detail.route.startsWith("admin-projects")) {
        document.querySelector('solid-route[name="admin-projects"]').setAttribute('active','');
        document.querySelector('.jsRightMenu solid-link[next="admin-projects"]').setAttribute('active','');
      } else {
        let adminProjects = document.querySelector('solid-route[name="admin-projects"]');
        let rightMenu = document.querySelector('.jsRightMenu solid-link[next="admin-projects"]');
        if( adminProjects ) adminProjects.removeAttribute('active');
        if( rightMenu ) rightMenu.removeAttribute('active');
      }
      closeLeftMenu();
      closeUserControls();
    });
    // Document -> close menu
    document.addEventListener("click", event => {
      if (!event.target.closest(".user-controls")) {
        closeUserControls();
      }
      if (
        !event.target.closest("#main__menu") &&
        event.target.id != "toggleMainMenu"
      ) {
        closeLeftMenu();
      }
      if (
        !event.target.closest(".jsOffsiteToggle")
      ) {
        closeRightMenu();
      }
    });

    // listen for keypress
    document.onkeydown = e => {
      e = e || window.event;
      if (e.key === "Escape" || e.key === "Esc") {
        closeUserControls();
        closeLeftMenu();
        closeRightMenu();
      }
    };

    document.querySelector("#toggleMainMenu").addEventListener("click", () => {
      let leftMenu = document.querySelector("#main__menu");
      if (leftMenu.hasAttribute("open")) {
        closeLeftMenu();
      } else {
        leftMenu.setAttribute("open", "");
      }
    });

    const rightMenus = Array.from(document.querySelectorAll("nav.jsRightMenu"));
    rightMenus.forEach(rightMenu => {
      const btnRightMenu = rightMenu.querySelector("li.jsOffsiteToggle");
      btnRightMenu.addEventListener("click", () => {
        if (rightMenu.hasAttribute("open")) {
          closeRightMenu();
        } else {
          openRightMenu();
        }
      });
    });

    Array.from(document.querySelectorAll(".jsMobileSidebarOpenButton")).forEach(
      el => {
        el.addEventListener("click", () => {
          openRightMenu();
        });
      }
    );
});
